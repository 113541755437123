<template>
  <div id="stats-week">
    <StatsAccountWeek />
  </div>
</template>

<script>
import StatsAccountWeek from '../../../components/stats/account/StatsAccountWeek'

export default {
  components: {
    StatsAccountWeek
  }
}
</script>
