import DateHelper from '@/util/DateHelper'

let StatsBaseWeekGridMixin = {
  methods: {
    defaultFrom: () => DateHelper.toDateString(DateHelper.previousMonday(DateHelper.daysAgo(29))),
    defaultTo: () => DateHelper.toDateString(DateHelper.previousMonday(DateHelper.today())),
    isMonday: date => DateHelper.isMonday(new Date(date)),
    isSunday: date => DateHelper.isSunday(new Date(date))
  }
}

export default StatsBaseWeekGridMixin
